import React from 'react'

import Box from '@mui/material/Box';

const Card = (props) => {
  const {
    theme='transparent',
    variant='regular',
    center=false,
    children,
    action,
    figure,
    sx
  } = props;

  const themes = {
    transparent: {
      border: 'transparent',
      background: 'transparent',
    },
    daybreak: {
      border:'#e2e2e2',
      background: '#fffbfa'
    },
    mint: {
      border:'#cdedf5',
      background:'#f2fbfc'
    }
  }

  const variants = {
    tall: {
      height: '100%',
    },
    regular: {
      height: 'auto',
    }
  }

  return (
    <Box
      sx={{
        textAlign:(center) ? 'center' : 'left',
        border: '1px solid',
        borderColor: themes[theme].border,
        backgroundColor: themes[theme].background,
        borderRadius: '20px',
        padding: '36px',
        display: 'flex',
        flexDirection: 'column',
        height: variants[variant].height,
        justifyContent: 'space-between',
        ...sx
      }}>
      <article>
        {(figure) &&
          <Box
            sx={{
              '& figure': {
                marginBlock: 'auto',
                marginInline: 'auto',
              },
              '& img': {
                borderRadius: '20px',
                width: '100%',
              },
            }}
          >
            <figure>
              {figure}
            </figure>
          </Box>
        }
        {children}
      </article>
      {(action) &&
        <Box>
          {action}
        </Box>
      }
    </Box>
  )
}

export default Card