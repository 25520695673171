import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

import Page from '../../../components/Page';
import Section from '../../../components/Section';
import Typography from '../../../components/Typography';
import Card from '../../../components/Card';
import SafeLink from '../../../components/SafeLink';

const useStyles = makeStyles(theme => ({
    bullet: {
      '&:before': {
        content: '"\u2022"'
      }
    }
  }));

const EmotionsFeelingsAndMoods = () => {

    const classes = useStyles();

    return (
        <Page title='Emotions, Feelings, and Moods: What&apos;s the difference?'>
            <Section theme='primary'>
                <Typography type='hero' preset={1}>Emotions, Feelings, and Moods: What's the difference?</Typography>
                <Typography type='paragraph' preset={1}>In here: Emotional health, Daily activity</Typography>
                <Typography type='paragraph' preset={1}>Joshua McInnes <span className={ classes.bullet } /> 5 min read time</Typography>
            </Section>
            <Section>
                <StaticImage alt='Sun shining through the trees' src='../../../images/article-hero-d03024a6-1ee1-49f8-bc86-5d12b3259d37.png' style={{width: '100%'}} />
            </Section>
            <Section>
                <Typography type='paragraph' preset={1}>What's the difference between a feeling and an emotion?</Typography>
                <Card theme='mint'>
                    <Typography type='heading' preset={2}>Summary</Typography>
                    <Typography type='paragraph' preset={1}>There is a direct relationship between our thoughts and our emotions.</Typography>
                    <ul>
                    <li>
                            <Typography type='paragraph' preset={1}><em>Thoughts</em> are our perception of stimulus and triggers</Typography>
                        </li>
                        <li>
                            <Typography type='paragraph' preset={1}>Thoughts trigger <em>emotions</em>: chemical reactions in our body.</Typography>
                            <ul>
                                <li>
                                    <Typography type='paragraph' preset={1}>These are physiological and don't last very long.</Typography>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Typography type='paragraph' preset={1}>Our perception of our emotions creates <em>feelings</em>.</Typography>
                            <ul>
                                <li>
                                    <Typography type='paragraph' preset={1}>These are cognitive and last longer.</Typography>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Typography type='paragraph' preset={1}><em>Moods</em> are more complex, general combinations of feelings and emotions.</Typography>
                        </li>
                    </ul>
                </Card>
                <Typography type='paragraph' preset={1}>Feelings, emotions, and moods. These words are often used to mean the same thing, but the terms aren't interchangeable. There are subtle and nuanced differences between all of them, but where one stops and the other begins isn't always clearly understood.</Typography>
                <Typography type='paragraph' preset={1}>Knowing the differences between these concepts can be helpful in growing our emotional literacy - our ability to understand not just the world around us but how it affects us mentally. When we understand our perceptions better, it can help us manage them and resist being overwhelmed.</Typography>
                <Typography type='paragraph' preset={1}>With that in mind, let's take a closer look at the distinctions.</Typography>

                <Typography type='heading' preset={2}>Thoughts</Typography>
                <Typography type='paragraph' preset={1}>We'll begin with our sensory input. Thoughts are our perception of events, stimulus, and our environment. They are a cognitive function taking place within our mind. Kateri McRae, a <SafeLink to='https://scholar.google.com/citations?user=igOYGOYAAAAJ&hl=en'>University of Denver psychologist</SafeLink> who works with the study of emotions says, "a lot of times, our feelings are preceded by certain thoughts." This means our perception of things often shapes our emotional responses and feelings.</Typography>

                <Typography type='heading' preset={2}>Emotions</Typography>
                <Typography type='paragraph' preset={1}>Emotions are physiological. They <SafeLink to='https://books.google.com.au/books?hl=en&lr=&id=McrLCgAAQBAJ&oi=fnd&pg=PA49&dq=emotions+feelings&ots=AglxyoqXHB&sig=vvA8mCKsAv4WFygc_akRjSCYIW4&redir_esc=y#v=onepage&q=emotions%20feelings&f=false'>are a biological response</SafeLink> that occurs unconsciously and takes place within our body over a short amount of time. How short?</Typography>
                <Typography type='paragraph' preset={1}>Well according to Harvard Neuroanatomist <SafeLink to='https://www.drjilltaylor.com/'>Dr. Jill Bolte Taylor</SafeLink>, 90 seconds is about how long it takes for the emotion inducing chemicals to completely dissipate once released. Dr Taylor explains;</Typography>
                <Typography type='paragraph' preset={1}><em>"When a person has a reaction to something in their environment," she says, "there's a 90-second chemical process that happens in the body; after that, any remaining emotional response is just the person choosing to stay in that emotional loop."</em></Typography>
                <Typography type='paragraph' preset={1}>Emotions are a way for our bodies to very quickly and efficiently receive and process data about our environment. They are essential to our daily activity and functioning.</Typography>
                <Typography type='paragraph' preset={1}>Noted neurologist <SafeLink to='https://www.scientificamerican.com/article/feeling-our-emotions/'>Antonio R. Damasio elaborates</SafeLink>;</Typography>
                <Typography type='paragraph' preset={1}><em>". . .for neuroscience, emotions are more or less the complex reactions the body has to certain stimuli. When we are afraid of something, our hearts begin to race, our mouths become dry, our skin turns pale and our muscles contract. This emotional reaction occurs automatically and unconsciously."</em></Typography>

                <Typography type='heading' preset={2}>Feelings</Typography>
                <Typography type='paragraph' preset={1}><SafeLink to='https://books.google.com.au/books?hl=en&lr=&id=McrLCgAAQBAJ&oi=fnd&pg=PA49&dq=emotions+feelings&ots=AglxyoqXHB&sig=vvA8mCKsAv4WFygc_akRjSCYIW4&redir_esc=y#v=onepage&q=emotions%20feelings&f=false'>Feelings are cognitive</SafeLink> and can last longer than their chemical counterparts, emotions. They are something we perceive consciously, made up of physical and mental sensations that occur as we internalise emotions, of which they are often made up of a mix.</Typography>
                <Typography type='paragraph' preset={1}>As the emotional chemicals are 'felt' and we begin to process them cognitively, integrating them into our perception, we formulate feelings.</Typography>
                <Typography type='paragraph' preset={1}>This is how we 'decode' or make sense of the physiological sensations triggered by our emotions.</Typography>

                <Typography type='heading' preset={2}>Moods</Typography>
                <Typography type='paragraph' preset={1}>Moods are often <SafeLink to='https://link.springer.com/content/pdf/10.1007/s11098-016-0650-2.pdf'>defined as more generalised</SafeLink> and made up of a combination of emotions and feelings.</Typography>
                <Typography type='paragraph' preset={1}>They are often more in-flux, and semi-persistent, coming and going. As a result, they may last longer than individual emotions or feelings - anywhere from a minute to whole days.</Typography>
                <Typography type='paragraph' preset={1}>Moods are both physical, chemical, and mental states. However, they may also be shaped by an arrangement of factors such as:</Typography>
                <ul>
                <li>
                        <Typography type='paragraph' preset={1}>Environment (temperature, noise, comfort)</Typography>
                    </li>
                    <li>
                        <Typography type='paragraph' preset={1}>Physiology (emotions, diet, exercise, health)</Typography>
                    </li>
                    <li>
                        <Typography type='paragraph' preset={1}>Mental state (feelings, focus, cognition).</Typography>
                    </li>
                </ul>
                <Typography type='paragraph' preset={1}>All these inputs come together to shape a <SafeLink to='https://www.6seconds.org/2017/05/15/emotion-feeling-mood/'>general sense of mood</SafeLink>.</Typography>

                <Typography type='heading' preset={2}>What They're All For</Typography>
                <Typography type='paragraph' preset={1}>Emotions, feelings, and moods are tools. The stimuli that trigger our feelings and emotions usually stick around or recur. By developing more complex, longer lasting moods we can physiologically and mentally stay in the right gear to tackle dangers and opportunities present in those stimuli. And emotions allow us to very quickly judge and react to more immediate opportunities and threats.</Typography>
                <Typography type='paragraph' preset={1}>The danger is when our thought patterns trigger unnecessarily negative emotional responses. These can develop into unwanted feelings and moods. These can, in turn, reinforce our negative thoughts and create a kind of emotional loop that results in overwhelmingly negative mental states.</Typography>

                <Typography type='heading' preset={2}>What We Can Do</Typography>
                <Typography type='paragraph' preset={1}>However, the above distinctions reveal that there is a direct link between our thoughts and how we end up feeling. This means we can exercise agency over our mood by adjusting those initial thought patterns.</Typography>
                <Typography type='paragraph' preset={1}>"When we shift our thoughts, that can precipitate a change in our emotions," <SafeLink to='https://www.vox.com/science-and-health/22641291/global-mental-health-covid-19-cognitive-reappraisal-study'>Kateri McRae explains</SafeLink>.</Typography>
                <Typography type='paragraph' preset={1}>This is the cognitive behavioural therapy technique known as Cognitive Reframing which we explore in our multi-part series <SafeLink to='https://individual.innowell.org/learn/Article/e855e3ea-c2d8-44aa-83cb-6c60f041e23c'>you can begin here</SafeLink>.</Typography>

                <Typography type='heading' preset={2}>Things to Remember</Typography>
                <Typography type='paragraph' preset={1}>Emotions, feelings and moods are often conflated in everyday conversation, clinically there are subtle distinctions, however. Understanding and being aware of these differences can help us understand what we may be experiencing physically and mentally. This can help us manage unwanted or overwhelming feelings.</Typography>
                <Typography type='paragraph' preset={1}>It's important to remember too that our emotions and moods - even unwanted ones - aren't bad. They're important tools when it comes to interpreting the world around us. Our Thoughts and feelings allow us to process and perceive them. All of them play an important role as survival mechanisms and are integral to our daily functioning, emotional health, and self-image.</Typography>
                <Typography type='paragraph' preset={1}>It's only when our feelings become overwhelming and disrupt our emotional health, daily activity, or relationships that we need to act, not to remove them, but to understand, regulate, and manage healthier emotional processes.</Typography>

                <Typography type='paragraph' preset={1}><i>TRIVIA: Feelings last longer than memories. <SafeLink to='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2676782/'>Studies have shown people</SafeLink> can remember how they felt even if the details of a memory are unclear - even in patients with amnesia or Alzheimer's.</i></Typography>
            </Section>
        </Page>
    )
};

export default EmotionsFeelingsAndMoods;